import { Box, Button, Grid, TableCell, TableRow } from "@mui/material";
import { Link } from "react-router-dom";
import { changeStatus } from "api/line";
import { LineMoneyDetail, LineProcess } from "../../components/line";
import useAuth from "../../hooks/useAuth";

const LineRow = ({ row, registerNewNode, unregistNode, removeLineNode }) => {
  // Get current email
  const {
    isAuthenticated,
    user: { email },
  } = useAuth();

  return (
    <TableRow key={row.name}>
      <TableCell>
        <Box className="imgProductArea">
          <Link to={`/line/${row.bladeUnitID}`}>
            <img
              src={row.bladeImageFull || "/upload/images/avatar-code.png"}
              style={{
                width: "100%",
              }}
              className="imgProduct"
            />
          </Link>
          <div className="progress mt-2">
            <div className="progress-bar" style={{ width: `${row.remainingPercent || 0}%` }}></div>
          </div>
          <h6 className="textProduct mt-2">{row.bladeUnitID}</h6>

          {email === row.emailCreator && (
            <Button
              variant="outlined"
              size="small"
              onClick={() => {
                removeLineNode({
                  bladeUnitID: row.bladeUnitID,
                  lineId: row.id,
                });
              }}
            >
              Hủy Line
            </Button>
          )}
        </Box>
      </TableCell>

      <TableCell>
        <Grid container direction={"column"} gap={3}>
          <Grid item>
            <LineMoneyDetail data={row} />
          </Grid>
          <Grid item>
            <LineProcess data={row} registerNewNode={registerNewNode} unregistNode={unregistNode} />
          </Grid>
        </Grid>
      </TableCell>
    </TableRow>
  );
};

export { LineRow };
